import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [

    {
        id: 1,
        label: 'Dashboard',
        icon: 'bx-home-circle',
        link:'/dashboard'
    },
    {
        id: 2,
        label: 'Press Clients',
        icon: 'bx bx-user',
        link:'/press'
    },
    {
        id: 3,
        label: 'Spotify Clients',
        icon: 'bx bxl-spotify',
        link:'/spotify'
    }
    

];

