import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  ApiUrl: string = environment.apiBaseUrl;
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private http: HttpClient) { }



  validateLogin(formData: any) {
    return this.http.post<any>(`${this.ApiUrl}/login`, formData, { headers: this.headers })
        .pipe(map(user => {
            if (user && user.token) {
              localStorage.setItem('currentauthor', JSON.stringify(user));
          }
        }));
  }

  public get getAuthenticatedUser() {
    let accessTokenObj = JSON.parse(localStorage.getItem("currentauthor"));
      if(accessTokenObj) {
        return accessTokenObj;
      }
  }

  
}
